html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
html {
  transition: opacity 0.3s;
}
html.wf-loading {
  opacity: 0;
}
html.wf-active {
  opacity: 1;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
